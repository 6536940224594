import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"

const linkStyle = {
  color: "inherit",
}
const About = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="About us" />
        <Typography variant="h2" color="inherit" style={{ marginBottom: 20 }}>
          A propos de nous
        </Typography>
        <Typography variant="body1" color="inherit">
          Le projet BARIAMED a été developé en collaboration avec:
          <ul style={{ marginTop: 4 }}>
            <li>
              <div>
                <strong>Pr Jean-Pierre FAURE,</strong> Professeur Universitaire
                - Praticien Hospitalier au CHU de Poitiers, Chirurgie Générale,
                Digestive et Chirurgie de l’Obésité.
              </div>
            </li>
            <li>
              <div>
                <strong>Dr David SOUSSI BERJONVAL,</strong> Praticien
                Hospitalier au CHU de Poitiers, Chirurgie Viscérale, Chirurgie
                Bariatrique.
              </div>
            </li>
            <li>
              <div>
                <strong>Sophie PEIFFER,</strong> Médecin généraliste, Diplôme Universitaire 
                de Nutrition clinique et thérapeutique. 
              </div>
            </li>
          </ul>
          Sources:
          <ul style={{ marginBottom: 0, marginTop: 4 }}>
            <li>
              <div>
                Protocole du CHU de Poitiers sur l’adaptation des traitements
                anti-diabétiques au décours de la chirurgie bariatrique élaboré
                par le <strong>Dr Xavier PIGUEL</strong>, Praticien Hospitalier
                et chef de service d’Endocrinologie, Diabétologie, Nutrition au
                CHU de Poitiers.
              </div>
            </li>
            <li>
              <a
                style={linkStyle}
                target="_blank"
                href="https://www.has-sante.fr/upload/docs/application/pdf/2011-10/reco2clics_obesite_adulte_chirurgie.pdf"
              >
                https://www.has-sante.fr/upload/docs/application/pdf/2011-10/reco2clics_obesite_adulte_chirurgie.pdf
              </a>
            </li>
            <li>
              <a
                style={linkStyle}
                target="_blank"
                href="https://www.has-sante.fr/upload/docs/application/pdf/2013-03/07r08_info_mg_chirurgie_obesite_document_medecin_traitant.pdf"
              >
                https://www.has-sante.fr/upload/docs/application/pdf/2013-03/07r08_info_mg_chirurgie_obesite_document_medecin_traitant.pdf
              </a>
            </li>
          </ul>
          <div style={{ marginTop: 20 }}>
            Contenu élaboré par <strong>Sophie PEIFFER</strong>, médecin
            généraliste – Diplôme Universitaire de Nutrition clinique et thérapeutique.
          </div>
          <div style={{ marginTop: 20 }}>
            Developpé par{" "}
            <a style={linkStyle} target="_blank" href="https://github.com/PBRT">
              Pierre Beard
            </a>
            , basé sur{" "}
            <a
              style={linkStyle}
              target="_blank"
              href="https://github.com/shantanu-deshmukh/vhealth-gatsby"
            >
              Vhealth Gatsby Theme
            </a>
          </div>
        </Typography>
        <Typography variant="body1" color="inherit" style={{ marginTop: 20 }}>
          <i>
            BARIAMED est un site d’information à destination des médecins généralistes, dont l’objectif est de
            favoriser la compréhension du suivi des patients après une chirurgie bariatrique.
            Les données présentes sur ce site ont été élaborées à partir de recommandations issues des sociétés
            savantes, et complétées par des notions pratiques issues de chirurgiens spécialisés dans la chirurgie
            bariatrique. Ce site ne remplace aucunement une consultation médicale ou chirurgicale.{" "} 
            <strong>Le site BARIAMED.FR n&#39;exerce ni directement, ni indirectement la médecine et ne pourra en aucun cas être tenu
            responsable de l&#39;usage des informations du site à des fins médicales.
            </strong> 
            {" "}L’utilisation du contenu du site s’effectue donc sous la seule responsabilité de l’utilisateur.
          </i>
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default About
